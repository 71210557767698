<template>
  <div class="technicalTeam">
    <!-- 团队总介绍 -->
    <div class="tt-introduce">
      <div class="tt-img">
        <el-image
          style="width: 400px; height: 300px"
          :src="pageData.img"
          fit="cover"
        ></el-image>
        <el-image
          style="width: 400px; height: 300px"
          :src="pageData.jsonText"
          fit="cover"
        ></el-image>
      </div>

      <div class="ti-text" v-html="pageData.richText"></div>
    </div>
    <!--  -->
  </div>
</template>

<script>
import axios from "axios"; //引入axios
export default {
  name: "",
  data() {
    return {
      pageData: "",
    };
  },
  watch: {},
  created() {},
  mounted() {
    this.queryTitleId();
  },
  methods: {
    queryTitleId() {
      let lang = localStorage.getItem("lang") || "CN";
      axios
        .get("https://www.claybbt.com/queryTitleId", {
          params: {
            language: lang,
            titleId: this.$route.query.nodeId,
          },
        })
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.data.pageDatas[0]) {
              this.pageData = res.data.data.pageDatas[0];
            }
          } else {
            this.$message.error(data.msg);
          }
        });
    },
  },
  computed: {},
  filters: {},
};
</script>

<style lang="less" scoped>
.technicalTeam {
  width: 100%;
  .tt-introduce {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .tt-img {
      display: flex;
      justify-content: space-between;
      min-width: 810px;
    }
    .ti-text {
      margin-left: 20px;
      font-size: 15px;
      line-height: 36px;
      color: #4d4d4d;
      height: 300px;
      overflow: auto;
      padding-right: 10px;
      box-sizing: border-box;
    }
  }
}
</style>
<style>
/* charset "utf-8"; */
::-webkit-scrollbar {
  width: 5px;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.1);
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgba(0, 0, 0, 0.1);
}
</style>
